






































































import { Vue, Component } from "vue-property-decorator";

@Component
export default class SuspensionPopup extends Vue {
  wxtpye = false;
  phonetype = false;

  onChatClick() {
      (window as any).qimoChatClick();
    }
}
