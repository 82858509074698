
export default {
  state: () => ({
    isLogin: false,
    showLoginPopup: false,
    userInfo: {},
    socketOptions: null as any,
    messageOptions: [] as string[],
    showMessage: false,
    globalKey: 0,
  }),
  mutations: {
    changeIsLogin(state: any, payload: boolean) {
      state.isLogin = payload
    },
    changeShowLoginPopup(state: any, payload: boolean) {
      state.showLoginPopup = payload;
    },
    changeUserInfo(state: any, payload: any) {
      state.userInfo = payload;
    },
    updateSocket(state: any, payload: any) {
      state.socketOptions = payload
    },
    changeMessageOptions(state: any, payload: string[]) {
      state.messageOptions = payload;
    },
    changeShowMessage(state: any, payload: any) {
      state.showMessage = payload
    },
    changeGlobalKey(state: any, payload: number) {
      state.globalKey = payload;
    }
  }
}
