

























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getnavigationCategory, getpath } from '@/api/home'
import HeadTopDropdown from './dropdown/index.vue';
import HeadAction from './HeadAction.vue';
import PresentationAddress from './address/PresentationAddress.vue'
@Component({
  components: {
    HeadTopDropdown,
    HeadAction,
    PresentationAddress
  }
})
export default class HeadTop extends Vue {
  @Prop()
  datas: any
  search = ''
  activeIndex = '1'
  miniactiveIndex = 0
  secondaryType = false //二级导航显隐
  r_search = ''
  navigationlist: any = []
  navigationProductVoList: any = {}
  nav = 'pages'
  showAddress = false
  // dialogVisible = false
  zhe = false
  isHiddenDropdown = true
  isShowSearch = false
  hoverMenuItem(item: any) {
    console.log(item);
    this.isHiddenDropdown = false;
  }
  created() {
      // if (this.$route.path == '/newsdetails') {
      //   this.nav = 'page_none '
      // }   
    getnavigationCategory().then((res) => {
      res.data.forEach((item: any) => {
        if (!item.navigationProductVos) {
          item.navigationProductVos = []
        } else {
          item.navigationProductVos.forEach((items: any) => {
            const includePage = items.includePage.split('')
            if (!items.linkName) {
              const index = includePage.findIndex(idx => idx == 5)
              if (index > -1) {
                includePage.splice(index, 1)
              }
            }
            items.includePage = includePage
            if (items.id == this.$route.query.key) {
              this.navigationProductVoList = items
              this.activeIndex = String(items.id)
            }
          })
        }
      })
      // if (this.$route.path == '/newsdetails') {
      //   this.nav = 'page_none '
      // }
      this.navigationlist = res.data
      // let key = null
      // const firstNavigation = this.navigationlist[0]
      // if (firstNavigation.navigationProductVos && firstNavigation.navigationProductVos) {
      //   key = firstNavigation.navigationProductVos[0].id
      // }
      // console.log(this.$route, 'this.$route')
      // if (key && this.$route.path !== '/newsdetails') {
      //   if (this.$route.path === '/') {
      //     this.activeIndex = key
      //     this.handleSelect(key)
      //   }
      // }
      if (this.$route.path == '/') {
        this.activeIndex = '1'
      }
      if (this.$route.path == '/success') {
        this.activeIndex = '2'
      }
      if (this.$route.path == '/cooperation') {
        this.activeIndex = '3'
      }
      if (this.$route.path == '/news') {
        this.activeIndex = '4'
      }
      if (this.$route.path == '/product') {
        this.nav = 'pages page-scroll'
      }
    })
  }
  mounted() {
    window.addEventListener('scroll', () => {
      // console.log(window.scrollY)
      // console.log(this.activeIndex, 'index1111111111')
      if (
        this.activeIndex == '1' ||
        this.activeIndex == '2' ||
        this.activeIndex == '3' ||
        this.activeIndex == '4'
      ) {
        // console.log(window.scrollY, '第一')

        if (window.scrollY > 0) {
          // console.log(window.scrollY, '第二')
          // console.log(this.nav, 1111111222)
          this.nav = 'pages page-scroll'
          // if (this.$route.path == '/newsdetails') {
          //   this.nav = 'page_none '
          // }
        } else {
          this.nav = 'pages'
          // if (this.$route.path == '/newsdetails') {
          //   this.nav = 'page_none '
          // }
        }
      }
      if (window.scrollY >= 0 && this.secondaryType) {
        this.nav = 'pages page-scroll'
        // if (this.$route.path == '/newsdetails') {
        //   this.nav = 'page_none '
        // }
      }
    })
  }
  handleSelect(key: any) {
    this.activeIndex = String(key)
    if (key == 1) {
      this.secondaryType = false
      this.$router.push({ path: '/' })
      this.nav = 'pages'
    }
    if (key == 2) {
      this.secondaryType = false
      this.$router.push({ path: '/success' })
      this.nav = 'pages'
    }
    if (key == 3) {
      this.secondaryType = false
      this.$router.push({ path: '/cooperation' })
      this.nav = 'pages'
    }
    if (key == 4) {
      this.secondaryType = false
      this.$router.push({ path: '/news' })
      this.nav = 'pages'
    }
    if (key != 1 && key != 2 && key != 3 && key != 4) {
      this.navigationlist.forEach((item: any) => {
        if (item.pageType === 'PRODUCT' && item.navigationProductVos.length != 0) {
          this.nav = 'pages page-scroll'
          item.navigationProductVos.forEach((items: any) => {
            if (items.id == key) {
              this.secondaryType = true
              this.navigationProductVoList = items
              this.go(items.includePage[0])
            }
          })
        }

        if (item.pageType === 'LINK' && item.id == key) {
          this.secondaryType = false
          if (item.openMode === 1) {
            if (item.customLink.startsWith('http')) {
              window.location.href = `${item.customLink}`
            } else {
              this.$router.push(item.customLink)
            }
          }
          if (item.openMode === 0) {
            if (item.customLink.startsWith('http')) {
              window.open(item.customLink, '_blank')
            } else {
              const routeUrl = this.$router.resolve(item.customLink);
              window.open(routeUrl.href, "_blank");
            }
          }
        }

        if (item.pageType === 'ARTICLE' && item.id == key) {
          this.secondaryType = false
          if (item.openMode === 1) {
            this.$router.push({
              path: '/newsdetails',
              query: { id: item.relevancePageId }
            })
          }
          if (item.openMode === 0) {
            let routeUrl = this.$router.resolve({
              path: '/newsdetails',
              query: { id: item.relevancePageId }
            })
            window.open(routeUrl.href, '_blank')
          }
        }

        if (item.pageType === 'ARTICLE_TAG' && item.id == key) {
          this.secondaryType = false
          if (item.openMode === 1) {
            this.$router.push({
              path: '/news',
              query: { id: item.relevancePageId }
            })
          }
          if (item.openMode === 0) {
            let routeUrl = this.$router.resolve({
              path: '/news',
              query: { id: item.relevancePageId }
            })
            window.open(routeUrl.href, '_blank')
          }
        }
      })
    }
  }
  hiden() {
    this.zhe = false
    // this.nav = 'pages'
    console.log('隐藏11111111')
  }
  // show_z() {
  //   this.zhe = true
  // }
  change_s() {
    // this.r_search = 'r_search-show '
    // this.nav = 'pages'
    // const inp = document.getElementsByClassName('el-input__inner')
    this.zhe = true
    // console.log(inp, 'inp111111111')
  }
  goindex() {
    // this.$router.push({ path: '/' })
    // this.activeIndex = '1' || ''
    // this.secondaryType = false
    // console.log(111)
    let id = ''
    if (this.navigationlist[0] && this.navigationlist[0].navigationProductVos && this.navigationlist[0].navigationProductVos[0]) {
      id = this.navigationlist[0].navigationProductVos[0].id
    } else if (this.navigationlist[0]) {
      id = this.navigationlist[0].id
    }
    if (id) {
      this.handleSelect(id)
    } else {
      this.$router.push({ path: '/' })
      this.activeIndex = '1' || ''
      this.secondaryType = false
    }
  }
  // gotocustomized() {
  //   this.$router.push({ path: '/customized' })
  //   this.secondaryType = false
  // }
  gotosearch() {
    this.$router.push({ path: '/search', query: { search: this.search } })
    this.secondaryType = false
    this.zhe = false
    this.search=''
  }
  // gotologin() {
  //   window.location.href = `https://www.bgniao.cn/login?redirect=%2Fconsole`;
  // }
  go(item: any) {
    const key: any = this.activeIndex
    this.nav = 'pages page-scroll'
    if (item == '1') {
      this.$router.push({ path: '/product', query: { key } })
    }
    if (item == '2') {
      this.$router.push({ path: '/price', query: { key } })
    }
    if (item == '3') {
      this.$router.push({ path: '/log', query: { key } })
    }
    if (item == '4') {
      this.$router.push({ path: '/case', query: { key } })
    }
    if (item == '5') {
      this.nav = 'pages page-scroll'
      if (this.navigationProductVoList.linkAddress) {
        if (this.navigationProductVoList.openMode == 0) {
          window.location.href = `${this.navigationProductVoList.linkAddress}`
        }
        if (this.navigationProductVoList.openMode == 1) {
          window.open(this.navigationProductVoList.linkAddress, '_blank')
        }
      }
    }
  }
  @Watch('$route')
  getproduct() {
    if (this.$route.path === '/userCenter') {
      this.activeIndex = ''
    }
    getpath({ path: this.$route.fullPath })
    if (this.$route.path == '/product') {
      this.secondaryType = true
      this.miniactiveIndex = 1
    }
    if (this.$route.path == '/price') {
      this.secondaryType = true
      this.miniactiveIndex = 2
    }
    if (this.$route.path == '/log') {
      this.secondaryType = true
      this.miniactiveIndex = 3
    }
    if (this.$route.path == '/case') {
      this.secondaryType = true
      this.miniactiveIndex = 4
    }
    if (this.$route.path == '/customized' || this.$route.path == '/search') {
      this.activeIndex = '0'
      this.secondaryType = false
      this.nav = 'pages'
    }
    // if (this.$route.path == "/newsdetails"|| this.$route.path == "/news") {
    //   this.activeIndex = "4";
    // }
  }
}
