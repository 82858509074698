import api from "@/libs/request";

export const registry = (data: any) => {
  return api.post('/pc/accountInfo/register', data)
};

export const getCode = (data?: any) => {
  return api.post("/send-code", data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const codeVerify = (data?: any) => {
  return api.put("/send-code/verify/code", data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const login = (data: any) => {
  return api.post('/account-info/login-v1', data)
}

export const changePassword = (data: any) => {
  return api.post('/front/changePassword', data)
}