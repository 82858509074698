/*
 * @description: 
 * @Author: latiao
 * @Date: 2021-11-25 09:50:55
 * @LastEditors: latiao
 * @LastEditTime: 2022-07-18 12:11:55
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/styles/base.scss";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
