















import { doGetDocumentLevel } from '@/api/document';
import HeadAction from '@/components/HeadAction.vue';
import MessagePopup from '@/components/dropdown/MessagePopup.vue';
import { mapData } from './helper';
export default {
  components: { MessagePopup, HeadAction },
  props: {
    datas: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      firstLevel: [] as any[],
      isHiddenMessagePopup: true,
      secondLevelMap: {} as any
    }
  },
  computed: {
    secondLevel() {
      const currentFirstLevel = this.$store.state.document.firstLevel;
      return this.secondLevelMap[currentFirstLevel] || []
    }
  },
  methods: {
    async getDocumentLevel() {
      const secondLevel = this.$route.query.category;
      const result = await doGetDocumentLevel();
      const resultData = result.data || []
      const secondLevelMap = this.secondLevelMap = mapData(resultData);
      Object.keys(secondLevelMap).forEach((key: unknown) => {
        const secondLevelList = secondLevelMap[key as number];
        const isExist = secondLevelList.find((item: any) => item.id === secondLevel);
        if (isExist) {
          this.$store.commit('document/changeFirstLevel', key)
          this.$store.commit('document/changeSecondLevel', secondLevel)
        }
      })
      this.firstLevel = resultData.map((item: any) => ({...item, children: void 0, id: String(item.id)}));
      if (!this.$store.state.document.secondLevel) {
        this.$store.commit('document/changeFirstLevel', this.firstLevel.length ? this.firstLevel[0].id : null)
        if (this.secondLevel.length) {
          this.$store.commit('document/changeSecondLevel', this.secondLevel.length ? this.secondLevel[0].id : null)
        } else {
          this.$store.commit('document/changeSecondLevel', null)
        }
      }
    },
    changeFirstLevel(firstLevel: string) {
      this.$store.commit('document/changeFirstLevel', firstLevel)
      if (this.secondLevel.length) {
        this.$store.commit('document/changeSecondLevel', this.secondLevel[0].id)
      } else {
        this.$store.commit('document/changeSecondLevel', null)
      }
    },
    changeSecondLevel(secondLevel: string) {
      this.$store.commit('document/changeSecondLevel', secondLevel)
    }
  },
  mounted() {
    this.getDocumentLevel()
  }
}
