
export const basicRegistryFormRules = {
  phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, {
    validator: (_, value, callback) => {
      const phoneNumberRegex = /^(?:\+?86)?1[3-9]\d{9}$/;
      if (phoneNumberRegex.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确手机号'))
      }
    }, trigger: 'blur'
  }],
  certificate: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
  password: [{ required: true, message: '请输入6-12位新密码', trigger: 'blur' }, { min: 6, max: 12, message: '请输入6-12位新密码', trigger: 'blur' }],
  confirmPassword: [{ required: true, message: '请输入6-12位确认密码', trigger: 'blur' }]
}