/*
 * @description:
 * @Author: latiao
 * @Date: 2021-11-25 09:50:55
 * @LastEditors: latiao
 * @LastEditTime: 2022-07-18 12:11:23
 */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/price",
    name: "Price",
    component: () => import("../views/Price.vue"),
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("../views/Log.vue"),
  },
  {
    path: "/case",
    name: "Case",
    component: () => import("../views/Case.vue"),
  },
  {
    path: "/customized",
    name: "Customized",
    component: () => import("../views/Customized.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("../views/Success.vue"),
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("../views/Cooperation.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/newsdetails",
    name: "NewsDetails",
    component: () => import("../views/NewsDetails.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: '/userCenter',
    name: "UserCenter",
    component:  () => import('../views/user-center/index.vue')
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import('../views/document/index.vue')
  },
  {
    path: '/suggest',
    name: 'Suggest',
    component: () => import('../views/suggest.vue')
  },
  {
    path: '/messageCenter',
    name: 'MessageCenter',
    component: () => import('../views/user-center/MessageCenter.vue')
  }
];

const router = new VueRouter({
  mode:"history",
  routes,
  base: process.env.VUE_APP_BASEPATH,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
}
});

export default router;
