















































import { basicRegistryFormRules } from './helper'
import PasswordStrength from './PasswordStrength.vue'
import { cloneDeep } from 'lodash'
import { codeVerify, getCode, registry } from '@/api/user/index'
export default {
  components: { PasswordStrength },
  data() {
    return {
      registryForm: {
        phone: '',
        certificate: '',
        nickName: '',
        password: '',
        confirmPassword: ''
      },
      isDisabled: false,
      countDown: 60,
      registryFormRules: {...basicRegistryFormRules, confirmPassword: [{ required: true, message: '请输入6-12位确认密码', trigger: 'blur' },{ validator: this.validateConfirmPassword, trigger: 'blur' }, { min: 6, max: 12, message: '请输入6-12位确认密码', trigger: 'blur' } ]}
    }
  },
  methods: {
    validateConfirmPassword(_: any, value: string, callback: (arg?: Error) => void) {
      if (value !== this.registryForm.password) {
        callback(new Error('两次密码不一致，请重新输入'));
      } else {
        callback()
      }
    },
    async handleGetCode() {
      await getCode({ phone: this.registryForm.phone, type: 1013 });
      this.$message.success('验证码发送成功')
      this.isDisabled = true
      const timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          this.isDisabled = false;
          this.countDown = 60;
          clearInterval(timer)
        }
      }, 1000);
    },
    handleRegistry() {
      this.$refs.registryFormRef.validate(async (isValid) => {
        if (isValid) {
          const result = await codeVerify({ phone: this.registryForm.phone, type: 1013, code: this.registryForm.certificate });
          // 请求注册接口
          const formData = cloneDeep(this.registryForm);
          delete formData.confirmPassword;
          formData.certificate = result.data
          try {
            const result = await registry(formData);
            if (result.code === 200) {
              this.$store.commit('user/changeShowLoginPopup', false)
              // 关闭弹窗
              this.$forceUpdate()
            }
          } catch (err) {
            this.$message.error(err)
          }
        }
      })
    }
  }
}
