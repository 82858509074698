/*
 * @description:
 * @Author: vikingShip
 * @Date: 2021-08-12 17:34:39
 * @LastEditors: latiao
 * @LastEditTime: 2022-07-20 17:51:08
 */
import Axios from "./axios";
import axiosInterceptor from "./axiosInterceptor";

const api = new Axios({
  baseURL: process.env.VUE_APP_BASEURL,
});

axiosInterceptor(api);
export default api;
