















export default {
  props: {
    messageItem: {
      type: Object,
      default: () => ({})
    }
  }
}
