












export default {
  computed: {
    messageOptions() {
      return this.$store.state.user.messageOptions
    },
    showMessage() {
      return this.$store.state.user.showMessage
    }
  }
}
