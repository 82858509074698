
const documentModule = {
  namespaced: true,
  state: () => ({
    firstLevel: null,
    secondLevel: null,
    thirdLevel: null,
    currentDocumentId: null
  }),
  mutations: {
    changeFirstLevel(state: { firstLevel: any; }, payload: any) {
      state.firstLevel = payload
    },
    changeSecondLevel(state: { secondLevel: any; }, payload: any) {
      state.secondLevel = payload;
    },
    changeThirdLevel(state: { thirdLevel: any }, payload: any) {
      state.thirdLevel = payload
    },
    changeCurrentDocumentId(state: { currentDocumentId: any }, payload: any) {
      state.currentDocumentId = payload
    },
  }
}

export default documentModule;