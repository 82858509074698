<template>
    <div class="popup">
        <div class="popup__container">
            <div class="popup__container--wrapper">
                <el-menu class="popup-menu" :default-active="active" @select="handleSearchMenuIndex">
                    <el-menu-item v-for="(address, index) in addressList" :key="index" :index="String(index)">{{ address.key }}</el-menu-item>
                </el-menu>
                <div class="popup__main">
                    <el-table size="mini" border :data="addressList[active].pc">
                        <el-table-column width="150" label="产品" prop="productName" />
                        <el-table-column label="演示地址" prop="productUrl">
                            <template slot-scope="{ row }">
                                <el-link :href="row.productUrl" target="_blank">{{ row.productUrl }}</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="popup__main--mobile">
                        <div class="popup__main--mobile-item" v-for="(item, index) in addressList[active].mobile" :key="index">
                            <img :src="item.productImageUrl" :alt="item.productName">
                            <span>{{ item.productName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { centralPlatformPresentationAddress, communityGroupPurchasePresentationAddress } from './presentationAddress'
export default {
    props: {
        productKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            active: '0'
        }
    },
    methods: {
        handleSearchMenuIndex(index) {
            this.active = index
        }
    },
    computed: {
        addressList() {
            return this.$route.query.key === '188' ? centralPlatformPresentationAddress : communityGroupPurchasePresentationAddress
        }
    }
}
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.popup {
    position: absolute;
    height: 520px;
    background-color: #fff;
    left: 0;
    right: 0;
    border-top: 1px solid #E1E1E1;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-delay: 0ms;
    &__container {
        width: 1200px;
        margin: 0 auto;
    }
    .el-menu-item {
        margin: 0 !important;
    }
    .popup-menu {
        width: 165px;
        float: left;
    }
    .popup__main {
        float: left;
        width: calc(100% - 165px);
        box-sizing: border-box;
        padding: 10px 25px;
        &--mobile {
            display: flex;
            align-items: center;
            margin-top: 15px;
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 90px;
                    height: 90px;
                }
                span {
                    font-size: 14px;
                    color: #333;
                    text-align: center;
                    margin-top: 8px;
                }
            }
            .popup__main--mobile-item + .popup__main--mobile-item {
                margin-left: 10px;
            }
        }
    }
}
.el-menu-item.is-active {
    border-bottom: none !important;
    border-left: 2px solid #3b8afe;
}
</style>