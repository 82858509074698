<template>
  <el-form :model="loginForm">
    <el-form-item prop="userPhone">
      <el-input v-model="loginForm.phone" placeholder="请输入手机号">
        <template slot="prefix">
          <img src="@/assets/image/icon/phone.png" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input v-model="loginForm.password" type="password" :maxlength="12" placeholder="请输入新密码">
        <template slot="prefix">
          <img src="@/assets/image/icon/password.png" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleLogin">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { login } from '@/api/user/index'
import storage from '@/libs/storage';
import dayjs from 'dayjs';
import useWebsocket from '@/useWebsocket';
export default {
  data() {
    return {
      loginForm: {
        phone: '',
        password: ''
      }
    }
  },
  methods: {
    async handleLogin() {
      try {
        const result = await login({ ...this.loginForm, accountType: 'FRONT' });
        if (result.data) {
          const createTime = dayjs(result.data.createTime).format('YYYY-MM-DD HH:mm:ss')
          storage.set('frontToken', result.data.token);
          storage.set('frontUserInfo', {...result.data, createTime});
          this.$store.commit('user/changeUserInfo', {...result.data, createTime});
          this.$store.commit('user/changeIsLogin', true);
          // 登录成功之后更新整个应用的key以重载整个应用，防止出现刷新不了的问题
          this.$store.commit('user/changeGlobalKey', Date.now())
          this.$store.commit('user/changeShowLoginPopup', false);
          useWebsocket(this)
        }    
      } catch (err) {
        this.$message.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.password {
  width: 100%;
}
img {
  vertical-align: middle;
}
</style>