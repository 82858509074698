



















import { doGetUserMessage, doPostUserReadMessage } from '@/api/message';
import MessageItems from './MessageItems.vue';
export default {
  components: { MessageItems },
  data() {
    return {
      activeName: 'SYSTEM_NOTIFICATION' as 'SYSTEM_NOTIFICATION' | 'NOTICE',
      itemList: [] as any[]
    }
  },
  methods: {
    async handleReadMessage() {
      try {
        const result = await doPostUserReadMessage(this.activeName);
        this.$message.success(result.msg || '已读所有消息');
        this.$store.commit('user/changeGlobalKey', Date.now())
      } catch (err) {
        this.$message.error(err as string)
      }
    },
    async getMessageList() {
      const userInfo = this.$store.state.user.userInfo;
      const result = await doGetUserMessage({ current: 1, size: 10, messageType: this.activeName, userId: userInfo.userId });
      if (result.data) {
        this.itemList = result.data.records;
      }
    }
  },
  mounted() {
    this.getMessageList()
  }
}
