import store from '@/store';
import Axios from './axios';
import storage from './storage';

const axiosInterceptor = (api: Axios) => {
  api.instance.interceptors.request.use(
    config => {
      if (config.headers && config.headers.type === "formData") {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded"; // 模拟form表单方式提交请求
      } else {
        config.headers.Accept = "application/json";
      }
      config.headers.Contenttype = "application/json";
      config.headers.token = storage.get("frontToken");
      config.headers.version = process.env.VUE_APP_APP_VERSION;
      config.headers.domainName = window.location.host;
      config.headers['Client-Type'] = 'front'
      // if (userStore.userInfo && userStore.userInfo.shopInfoVo) {
      //   config.headers.shopId = userStore.userInfo.shopInfoVo.shopId;
      //   config.headers.tenantId = userStore.userInfo.shopInfoVo.tenantId;
      // }
      return config;
    },
    err => {
      return Promise.reject(err);
    },
  );

  api.instance.interceptors.response.use(
    res => {
      // if (res.data.code === 3) {
      //   logout();
      //   router.push("/sign");
      // }
      // if (excludeUrls.find(i => res.config.url.indexOf(i) !== -1)) {
      //   return res.data;
      // }
      // store.commit('user/changeShowLoginPopup', true)
      if ([300006, 300007, 401].includes(res.data.code)) {
        // token过期没登录直接弹出登录弹窗
        if (res.config.url !== '/userMessage/unread') {
          store.commit('user/changeShowLoginPopup', true)
        }
        storage.delete('frontToken');
        storage.delete('frontUserInfo');
        store.commit('user/changeIsLogin', false);
        store.commit('user/changeUserInfo', {});
        return Promise.reject(
          res.data.msg || res.data.message || "未知错误，请稍后再试",
        );
      } else if (res.data.code !== 200 || res.status !== 200) {
        return Promise.reject(
          res.data.msg || res.data.message || "未知错误，请稍后再试",
        );
      } else {
        return res.data;
      }
    },
    err => {
      return Promise.reject(err);
    },
  );
}

export default axiosInterceptor;
