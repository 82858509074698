
const useWebsocket = (vm: Vue) => {
  const socketOptions = vm.$store.state.user.socketOptions;
  if (socketOptions) {
    socketOptions.close();
    vm.$store.commit('user/updateSocket', null);
  }
  if('WebSocket' in window) {
    // const websocket = new WebSocket("ws://192.168.1.104:8778/broadcast");
    const websocket = new WebSocket("wss://www.bgniao.cn/broadcast");
    websocket.onopen = function() {
      console.log('websocket opened');
      vm.$store.commit('user/updateSocket', websocket);
    }
    websocket.onmessage = function(websocketOptions) {
      const data = websocketOptions.data;
      console.log('消息列表123：', JSON.parse(data))
      vm.$store.commit('user/changeMessageOptions', JSON.parse(data));
      vm.$store.commit('user/changeShowMessage', true);
    }
  } else {
    vm.$message.error('当前浏览器不支持Websocket')
  }
}

export default useWebsocket;