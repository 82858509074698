export const centralPlatformPresentationAddress = [
    {
        key: 'S2B2C',
        pc: [
            {
                productName: '平台端',
                productUrl: 'https://pro.bgniao.cn/platform/#/'
            },
            {
                productName: '供应商端',
                productUrl: 'https://pro.bgniao.cn/supplier/#/'
            },
            {
                productName: '商家端',
                productUrl: 'https://pro.bgniao.cn/shop/#/'
            },
            {
                productName: '商家移动端',
                productUrl: 'https://pro.bgniao.cn/mobile/#/'
            },
            {
                productName: '门店移动端',
                productUrl: 'https://pro.bgniao.cn/store/#/'
            },
            {
                productName: 'H5端',
                productUrl: 'https://pro.bgniao.cn/h5/'
            },
            {
                productName: 'PC端',
                productUrl: 'https://pro.bgniao.cn/pc/#/'
            }
        ],
        mobile: [
            {
                productName: '小程序',
                productImageUrl: require('@/assets/image/product/S2B2C.png')
            }
        ]
    },
    {
        key: 'B2B2C',
        pc: [
            {
                productName: '平台端',
                productUrl: 'https://b2b2c.bgniao.cn/platform'
            },
            {
                productName: '商家端',
                productUrl: 'https://b2b2c.bgniao.cn/shop'
            },
            {
                productName: 'H5端',
                productUrl: 'https://b2b2c.bgniao.cn/h5'
            },
            {
                productName: 'PC端',
                productUrl: 'https://b2b2c.bgniao.cn/pc'
            },
            {
                productName: '商家移动端',
                productUrl: 'https://b2b2c.bgniao.cn/mobile'
            },
            {
                productName: '门店移动端',
                productUrl: 'https://b2b2c.bgniao.cn/store'
            }
        ],
        mobile: [
            {
                productName: '小程序',
                productImageUrl: require('@/assets/image/product/S2B2C.png')
            }
        ]
    },
    {
        key: 'B2C',
        pc: [
            {
                productName: '商业版H5（生产环境）',
                productUrl: 'https://mall.bgniao.cn/h5/100002/#/'
            },
            {
                productName: '商业版（测试环境）',
                productUrl: 'https://test.bgniao.cn/copartner/1.0/sign'
            }
        ],
        mobile: [
            {
                productName: '小程序',
                productImageUrl: require('@/assets/image/product/B2C.jpg')
            }
        ]
    },
    {
        key: 'O2O',
        pc: [
            {
                productName: '平台端',
                productUrl: 'https://b2b2c.bgniao.cn/platform'
            },
            {
                productName: '商家端',
                productUrl: 'https://b2b2c.bgniao.cn/shop'
            },
            {
                productName: 'H5端',
                productUrl: 'https://b2b2c.bgniao.cn/h5'
            },
            {
                productName: 'PC端',
                productUrl: 'https://b2b2c.bgniao.cn/pc'
            },
            {
                productName: '商家移动端',
                productUrl: 'https://b2b2c.bgniao.cn/mobile'
            },
            {
                productName: '门店移动端',
                productUrl: 'https://b2b2c.bgniao.cn/store'
            }
        ],
        mobile: [
            {
                productName: '小程序',
                productImageUrl: require('@/assets/image/product/S2B2C.png')
            }
        ]
    }
]

export const communityGroupPurchasePresentationAddress = [
    {
        key: '社区团购',
        pc: [
            {
                productName: '社区团购',
                productUrl: 'https://group.bgniao.cn/copartner/1.0.1/sign'
            }
        ],
        mobile: [
            {
                productName: '小程序',
                productImageUrl: require('@/assets/image/product/purchase-qrcode.jpg')
            }
        ]
    }
]