/*
 * @description: 
 * @Author: latiao
 * @Date: 2021-12-08 16:25:40
 * @LastEditors: latiao
 * @LastEditTime: 2022-03-01 09:31:20
 */
import api from "@/libs/request";

/** pc/基础设置获取*/
export const postbase = () => {
  return api.post(`/base/pc/get`, {});
};
/** 链接列表*/
export const getlinklist = () => {
  return api.get(`/link/pc/list`, {});
};
/** pc查询导航*/
export const getnavigationCategory = () => {
  return api.get(`/pc/NavigationPage/getNavigationCategory`, {});
};
/** pc产品介绍*/
export const getproductIntroduction = () => {
  return api.get(`/pc/pageSettings/productIntroduction`, {});
};
/** pc解决方案*/
export const getSolution = () => {
  return api.get(`/pc/pageSettings/getSolution`, {});
};
/** pc营销介绍、安全系统、服务保障*/
export const getinfo = (data:any) => {
  return api.get(`/pc/pageSettings/info`, data);
};
/** pc展示首页轮播海报*/
export const gethomePageImgList = () => {
  return api.get(`/pc/NavigationPage/homePageImgList`, {});
};
/**pc成功案例*/
export const getsucceedCase = () => {
  return api.get(`/pc/NavigationPage/succeedCase`, {});
};
/**pc渠道合作*/
export const getchannelCooperationImg= () => {
  return api.get(`/pc/NavigationPage/channelCooperationImg`, {});
};
/**pc展示定制图片*/
export const getcustomizationImgList= () => {
  return api.get(`/pc/NavigationPage/pc/customizationImgList`, {});
};
/**表单信息*/
export const getformSettings= () => {
  return api.get(`/pc/formSettings/manager/info`, {});
};
/**表单数据新增或编辑*/
export const postformsave= (data:any) => {
  return api.post(`/form/pc/save`, data);
};
/**搜索案例*/
export const getsearchSucceedCase= (data:any) => {
  return api.get(`/pc/pageSettings/searchSucceedCase`, data);
};
/**前端路由访问接口*/
export const getpath= (data:any) => {
  return api.get(`/data/manager/list`, data);
};
