



















import { codeVerify, getCode } from '@/api/user';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';

export default {
  data() {
    return {
      modifyPasswordFormModel: {
        code: '',
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      isDisabled: false,
      countDown: 60,
      modifyPasswordFormRules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入6-12位原密码', trigger: 'blur' }, { min: 6, max: 12, message: '请输入6-12位原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入6-12位新密码', trigger: 'blur' }, { min: 6, max: 12, message: '请输入6-12位新密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请输入6-12位确认密码', trigger: 'blur' }, { min: 6, max: 12, message: '请输入6-12位确认密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    async handleGetCode() {
      const result = await getCode({ phone: this.$store.state.user.userInfo.phone, type: 1004 });
      this.$message.success('验证码发送成功')
      this.isDisabled = true
      const timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          this.isDisabled = false;
          this.countDown = 60;
          clearInterval(timer)
        }
      }, 1000);
    },
    validateConfirmPassword(_: any, value: string, callback: (arg?: Error) => void) {
      if (value !== this.modifyPasswordFormModel.newPassword) {
        callback(new Error('两次密码不一致，请重新输入'));
      } else {
        callback()
      }
    },
    getFormData() {
      const formRefs: Form | null = this.$refs.passwordRefs as Form | null;
      return new Promise((resolve, reject) => {
        if (formRefs) {
          formRefs.validate(async (isValid: boolean) => {
            if (isValid) {
              // 请求验证接口
              const result = await codeVerify({ phone: this.$store.state.user.userInfo.phone, type: 1004, code: this.modifyPasswordFormModel.code });
              // 请求注册接口
              const formData = cloneDeep(this.modifyPasswordFormModel);
              resolve({
                passwd: this.modifyPasswordFormModel.newPassword,
                certificate: result.data,
                phone: this.$store.state.user.userInfo.phone,
                accountType: 'FRONT'
              })
            } else {
              reject('valid false')
            }
          })
        } else {
          reject('form not found')
        }
      })
    }
  }
}
