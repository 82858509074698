











import Login from './login.vue';
import Registry from './registry.vue';
export default {
  data() {
    return {
      activeName: "login"
    };
  },
  components: { Login, Registry }
}
