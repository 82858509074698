









































import storage from '@/libs/storage';
import MessagePopup from './dropdown/MessagePopup.vue';
import ModifyPassword from './login/ModifyPassword.vue';
import { changePassword } from '@/api/user';
import { doGetUserUnreadMessageCount } from '@/api/message';
export default {
  components: { MessagePopup, ModifyPassword },
  data() {
    return {
      isHiddenMessagePopup: true,
      showModifyPasswordDialog: false,
      messageCount: 0
    }
  },
  methods: {
    handleLogin() {
      this.$store.commit('user/changeShowLoginPopup', true)
    },
    handleChangeCommand(command: string) {
      if (command === 'userCenter') {
        this.$router.push('/userCenter')
      } else if (command === 'modifyPassword') {
        this.showModifyPasswordDialog = true
      } else if (command === 'exitLogin') {
        this.$message.success('退出成功');
        storage.delete('frontToken');
        storage.delete('frontUserInfo');
        this.$store.commit('user/changeIsLogin', false);
        this.$store.commit('user/changeUserInfo', {});
        // 更新全局的Key，刷新整个应用
        this.$store.commit('user/changeGlobalKey', Date.now())
      }
    },
    handleCloseModifyPassword() {
      this.showModifyPasswordDialog = false;
    },
    async confirmModifyPassword() {
      const modifyPasswordRefs: InstanceType<typeof ModifyPassword> | null = this.$refs.modifyPasswordRefs as InstanceType<typeof ModifyPassword> | null;
      if (modifyPasswordRefs) {
        const data = await modifyPasswordRefs.getFormData();
        // 请求修改密码接口
        try {
          const result = await changePassword(data);
          this.$message.success(result.msg || '修改密码成功')
          this.showModifyPasswordDialog = false;
        } catch (err) {
          this.$message.error(err as string);
        }
      }
    },
    async getMessageCount() {
      const result = await doGetUserUnreadMessageCount();
      this.messageCount = result.data || 0
    }
  },
  mounted() {
    this.getMessageCount()
  },
}
