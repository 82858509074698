














import ContainerBlock from './ContainerBlock.vue';
export default {
  components: { ContainerBlock },
  props: {
    menuId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentMenuIndex: 1
    }
  }
}
