
































import { Vue, Component } from 'vue-property-decorator'
import foot from '@/components/Footer.vue'
import headTop from '@/components/HeadTop.vue'
import SuspensionPopup from '@/components/SuspensionPopup.vue'
import { postbase, getlinklist } from '@/api/home'
import RegistryLogin from '@/components/login/index.vue'
import 'animate.css'
import DocumentHead from './views/document/DocumentHead.vue'
import storage from './libs/storage'
import useWebsocket from './useWebsocket';
import MessageComp from './views/common/message.vue';
@Component({
  components: {
    foot,
    headTop,
    SuspensionPopup,
    RegistryLogin,
    DocumentHead,
    MessageComp
  }
})
export default class App extends Vue {
  datas = {}
  linkdata = []
  created() {
    postbase().then((res) => {
      document.title = res.data.websiteTitle || '启山智软'
      this.datas = res.data
    })
    getlinklist().then((res) => {
      this.linkdata = res.data
    })
  }
  mounted() {
    const storageUserInfo = storage.get('frontUserInfo');
    if (storageUserInfo) {
      this.$store.commit('user/changeIsLogin', true);
    }
    this.$store.commit('user/changeUserInfo', storageUserInfo || {});
    useWebsocket(this)
  }
  handleCloseLoginPopup() {
    this.$store.commit('user/changeShowLoginPopup', false)
  }
}
