import api from "@/libs/request";
/**
 * @description 获取用户未读的信息数量
 * @returns 
 */
export const doGetUserUnreadMessageCount = () => {
    return api.get('/pc/userMessage/unread', {})
}
/**
 * @description 已读公告/系统通知消息
 * @param messageType 通知类型
 * @returns 
 */
export const doPostUserReadMessage = (messageType: 'NOTICE' | 'SYSTEM_NOTIFICATION') => {
    return api.get(`/pc/userMessage/read`, {messageType})
}
/**
 * @description 获取用户消息全部列表
 * @param params 
 * @returns 
 */
export const doGetUserMessage = (params: any = {}) => {
    return api.get('/pc/userMessage', params)
}