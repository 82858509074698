
































































































import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class Footer extends Vue {
  @Prop()
  datas: any;
  @Prop()
  linkdata: any;
  open(ip: string) {
    window.open(ip, "_blank");
  }
  onChatClick() {
    (window as any).qimoChatClick();
  }
  gotocustomized() {
    this.$router.push({ path: "/customized" });
  }
}
