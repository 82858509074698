import api from "@/libs/documentRequest";
import store from "@/store";
const getTancentId = () => {
  return store.state.user.userInfo && store.state.user.userInfo.tenantId;
}
/**
 * @description 文档点赞
 * @param string documentId 文档id号
 * @returns 
 */
export const likeDocument = (documentId: string) => {
  return api.post(`/document/pc/like`, { documentId }, {
    headers: { tenantId: getTancentId() }
  });
}
/**
 * @description 获取导航层级列表
 * @returns 
 */
export const doGetDocumentLevel = () => {
  return api.get('/documentCategory/api/list', {}, {
    headers: { tenantId: getTancentId() }
  })
}
/**
 * @description 获取二级文档列表
 * @param documentCategoryLeve2Id 二级文档ID
 * @returns 
 */
export const doPostSecondLevelDocuments = (documentCategoryLeve2Id: string) => {
  return api.post(`/documentCategory/api/documentList/${documentCategoryLeve2Id}`, {}, {
    headers: { tenantId: getTancentId() }
  })
}
/**
 * @description 查询文档列表
 * @param params 查询参数
 * @returns 
 */
export const doGetSearchDocument = (params: { documentCategoryLeve2Id: string, keyword?: string }) => {
  return api.get('/documentCategory/api/search', params, {
    headers: { tenantId: getTancentId() }
  });
}
/**
 * @description 获取文档详情
 * @param id 
 * @returns 
 */
export const doGetDocumentDetails = (id: string) => {
  return api.get(`document/api/${id}`, {}, {
    headers: { tenantId: getTancentId() }
  })
}